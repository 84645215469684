const jwt = require('jsonwebtoken');
const moment = require('moment-timezone');

exports.sign = (claim = {}) => { 
    claim['exp'] = moment().add(1,'hour').unix()
    let publicKey = `-----BEGIN RSA PUBLIC KEY-----
MIIBCgKCAQEAopBMzs0lAGrS853fhzugd0QzCOH3uBZP7Zwhf1i2uUy6gmTFhguv
QOkzB2ANzmyTaNO0qLg7WTPxIigQCqB+doPBO73q/++iMU9ZeoUcPYPfriRJ+DR/
7FznXZWmKD+ilJqcxZNymihcUX1f15pT9dex3Mulgkvi/uH28AbNkZVBEZYlbRTm
CAIehmBeyAraV1G8W0d/p2Dxza2JqZMT49uzQUsmpgKF6oUA0SldXhoneHaKcm2o
IqoP9E2QEKDEsUkf94fXmWSUCpplNLWZKcQCC90lVlNzlHDVs5+NciXgMWaEYX7I
HwHCgplD0xWCAx8eT+PR8gZGG36Ol3jfnwIDAQAB
-----END RSA PUBLIC KEY-----
`
  
   
    let lockkey = Buffer.from(publicKey).toString('base64')
    try {
        let token = jwt.sign(claim, lockkey, {
            algorithm: process.env.VUE_APP_JWT_ALGORITHM,
        
        })
        return token
    } catch (e) {
        console.error(e)
        return null
    }
}
