<template>
  <div>
    <div v-if="source == 'qr'" class="text-center mt-5" style="justify-content: center;width: 100%;display: flex;">
      <div class="header-view">
        <div style="font-size:20px;margin-top: 14px;">
          {{ $t('paymentSuccess') }}</div>
      </div>
      <div class="receipt-review">
        <img style="z-index: 1080;" src="   https://cdn-icons-png.flaticon.com/512/4315/4315445.png " width="25%" alt=""
          title="" class="img-fluid mt-5 mb-3"><br>
        <h2> {{ branchName }}</h2>
        <small> {{ $t('receiptNumber') }} : {{ receiptObjectId }}</small><br>
        <div style="font-size: larger;" class="row p-3">
          <div class="col-6 text-left mt-2">{{ $t('paymentType') }}</div>
          <div class="col-6 text-right mt-2"> QR Payment </div>

          <div class="col-6 text-left mt-2">{{ $t('tableNumber') }}</div>
          <div class="col-6 text-right mt-2"> {{ tableName }} </div>

          <div class="col-6 text-left mt-2">{{ $t('countProduct') }}</div>
          <div class="col-6 text-right mt-2"> {{ receiptItems.length }} </div>
          <template v-if="!isVatInclude">
            <div class="col-6 text-left mt-2">{{ $t('netAmountVATTotal') }}</div>
            <div class="col-6 text-right mt-2">{{ netAmountVATTotal }} ฿</div>
            <div class="col-8 text-left mt-2">{{ $t('vatAmount') }} {{ vatBase }}%</div>
            <div class="col-4 text-right mt-2">{{ vatAmount }} ฿</div>

          </template>
          <template v-if="serviceCharge !== '0.00'">
            <div class="col-8 text-left mt-2">{{ $t('serviceCharge') }}</div>
            <div class="col-4 text-right mt-2">{{ serviceCharge }} ฿</div>
          </template>
          <template v-if="discount !== '0.00'">
            <div class="col-7 text-left mt-2">{{ $t('discount') }}</div>
            <div class="col-5 text-right mt-2">- {{ discount }} ฿</div>
          </template>


          <div class="col-6 text-left mt-4 h4">
            {{ $t('grandTotal') }}
          </div>
          <div class="col-6 text-right mt-4 h4">
            {{ grandTotal }}฿
          </div>

        </div>


        <div class="mb-2 mt-3">{{ $t('askForReceipt') }}</div><br>
        <span class="mt-4">
          Powered by
          <img src="/connect.png" width="5%" alt="" class="img-fluid" />
          Silom POS
        </span>
      </div>

    </div>
    <CContainer v-else class="d-flex align-items-center mt-2">
      <CRow class="justify-content-center col-md-12">
        <CCard color="white" text-color="dark" class="text-center col-md-6" body-wrapper>
          <div>
            <h3>Success</h3>
          </div>
        </CCard>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import local from '@/services/local'
import signed from '@/util/signed'

export default {
  name: 'Login',
  data() {
    return {
      title: '',
      msgError: '',
      msgSuccess: '',
      alert: '',
      delayAlert: 3,
      loadingButton: false,
      source: '',
      receiptObjectId: '',
      tableName: '',
      grandTotal: 0,
      receiptItems: [],
      branchName: '',
      isVatInclude: Boolean,
      vatBase: '',
      vatAmount: 0,
      serviceCharge: 0,
      discount: 0,
      netAmountVATTotal: 0,
    }
  },
  created() {
    this.getQRStatus()
  },
  methods: {
    BackToMenu() {
      this.$router.push('/dine-in/menulists')
    },
    getQRStatus() {
      const urlParams = new URLSearchParams(window.location.search);
      let token = signed.sign()
      let uid = urlParams.get('uid') || ''
      let source = urlParams.get('source') || ''
      let receiptObjectId = urlParams.get('order') || ''
      let shopObjectId = urlParams.get('shopObjectId') || ''
      let locale = urlParams.get('locale') || 'th'
      this.$i18n.locale = locale

      this.source = source
      this.receiptObjectId = receiptObjectId

      if (source !== 'qr') {
        window.close()
      }

      local.get('/api/v1.1/' + uid + '/Receipt/data/' + receiptObjectId, {
        headers: {
          token: token,
          shopObjectId: shopObjectId
        }
      })
        .then((res) => {
          let document = res.data.data.document
          this.tableName = document.table.name
          this.grandTotal = document.grandTotal.toFixed(2)
          this.receiptItems = document.receiptItems
          this.branchName = document.shop.branchName

          this.isVatInclude = document.isVATIncluded
          this.vatBase = document.vatBase
          this.vatAmount = document.vatAmount.toFixed(2)
          this.serviceCharge = document.serviceCharge.toFixed(2)
          this.discount = document.discountAmount.toFixed(2)
          this.isVATIncluded = document.isVATIncluded
          this.netAmountVATTotal = document.netAmountVATTotal.toFixed(2)
        })
    },
  },
}
</script>
<style>
.receipt-review {
  margin-top: 60px;
  margin-bottom: 80px;
  width: 80%;
  padding-bottom: 70px;
  background-color: #ffffff;
  border-radius: 5px;
  align-self: center;
  -webkit-mask-image: radial-gradient(circle at 50% 8px, transparent 8px, red 8.5px);
  -webkit-mask-position: 50% -8px;
  -webkit-mask-size: 16px 100%;
}

.header-view {
  background-color: #219b78;
  color: #ffffff;
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1060;
}
</style>